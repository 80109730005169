.loading-overlay {
  border-radius: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(10, 10, 10, 0.5);
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.css-loader {
  animation: spinner 3s linear infinite;
}
