@font-face {
  font-family: 'museo-sans';
  src: url('https://use.typekit.net/af/635674/00000000000000000000e800/27/l?subset_id=2&fvd=n1&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/635674/00000000000000000000e800/27/d?subset_id=2&fvd=n1&v=3')
      format('woff'),
    url('https://use.typekit.net/af/635674/00000000000000000000e800/27/a?subset_id=2&fvd=n1&v=3')
      format('opentype');
  font-display: swap;
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: 'museo-sans';
  src: url('https://use.typekit.net/af/47003b/00000000000000000000e801/27/l?subset_id=2&fvd=i1&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/47003b/00000000000000000000e801/27/d?subset_id=2&fvd=i1&v=3')
      format('woff'),
    url('https://use.typekit.net/af/47003b/00000000000000000000e801/27/a?subset_id=2&fvd=i1&v=3')
      format('opentype');
  font-display: swap;
  font-style: italic;
  font-weight: 100;
}

@font-face {
  font-family: 'museo-sans';
  src: url('https://use.typekit.net/af/620bf8/00000000000000000000e7fe/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/620bf8/00000000000000000000e7fe/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
      format('woff'),
    url('https://use.typekit.net/af/620bf8/00000000000000000000e7fe/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
      format('opentype');
  font-display: swap;
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'museo-sans';
  src: url('https://use.typekit.net/af/5cca6d/00000000000000000000e802/27/l?subset_id=2&fvd=i3&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/5cca6d/00000000000000000000e802/27/d?subset_id=2&fvd=i3&v=3')
      format('woff'),
    url('https://use.typekit.net/af/5cca6d/00000000000000000000e802/27/a?subset_id=2&fvd=i3&v=3')
      format('opentype');
  font-display: swap;
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: 'museo-sans';
  src: url('https://use.typekit.net/af/a28b50/00000000000000000000e803/27/l?subset_id=2&fvd=n5&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/a28b50/00000000000000000000e803/27/d?subset_id=2&fvd=n5&v=3')
      format('woff'),
    url('https://use.typekit.net/af/a28b50/00000000000000000000e803/27/a?subset_id=2&fvd=n5&v=3')
      format('opentype');
  font-display: swap;
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'museo-sans';
  src: url('https://use.typekit.net/af/c2d3de/00000000000000000000e804/27/l?subset_id=2&fvd=i5&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/c2d3de/00000000000000000000e804/27/d?subset_id=2&fvd=i5&v=3')
      format('woff'),
    url('https://use.typekit.net/af/c2d3de/00000000000000000000e804/27/a?subset_id=2&fvd=i5&v=3')
      format('opentype');
  font-display: swap;
  font-style: italic;
  font-weight: 500;
}
