@import 'mixins/modular-scale.scss';
@import 'mixins/font-builder.scss';

body {
  font-family: 'museo-sans', 'Helvetica Neue', sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  text-rendering: optimizeSpeed;
}

code {
  font-family: 'Courier New', monospace;
}

input,
button,
textarea,
select {
  font: inherit;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
p,
.paragraph {
  margin-top: modular-scale(1, 'ex');
  margin-bottom: modular-scale(0, 'ex');
  letter-spacing: 0.05ex;
}

h1,
.h1 {
  @include font-builder(36);

  font-weight: 300;
}

h2,
.h2 {
  @include font-builder(30);

  font-weight: 300;
}

h3,
.h3 {
  @include font-builder(18);

  font-weight: 500;
}

h4,
.h4 {
  @include font-builder(18);

  font-weight: 500;
}

h5,
.h5 {
  @include font-builder(16);

  font-weight: 500;
}

p,
.body-1 {
  @include font-builder(18, 1.5);

  font-weight: 300;
}

.body-2 {
  @include font-builder(18, 1.5);

  font-weight: 500;
}

.body-3 {
  @include font-builder(14, 1.7);

  font-weight: 300;
}

.body-4 {
  @include font-builder(14, 1.7);

  font-weight: 500;
}

.body-5 {
  @include font-builder(12, 1.5);

  font-weight: 500;
}
