@import 'src/components/forms/Input/Input.module.scss';

.datepickerContainer {
  display: flex;
  align-content: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
  // padding-right: 40px;
  // margin-right: 20px;
  caret-color: var(--neutral-1);
  color: var(--neutral-1);
  height: 48px;
  border: none;
  box-shadow: 0 1px 0 var(--neutral-5), 0 0 0 50px #fff inset;
  transition: box-shadow 200ms ease-out;
  -webkit-appearance: none;
  font-size: 0.875rem;
  line-height: 1;
  margin-bottom: 1em;

  &.read-only {
    background: var(--neutral-8);
    border-radius: 5px;
    box-shadow: none;
    cursor: not-allowed;
    color: var(--neutral-4);

    :global .react-datepicker-wrapper {
      margin-top: 0 !important;
    }
  }

  &.noClose {
    box-shadow: none;
    background: none;
  }
}

.datepicker {
  width: 100%;
  border: none;
  position: relative;
  background: none;

  &:focus {
    outline: none;
  }

  &[readonly] {
    // background: var(--neutral-8);
    box-shadow: none;
    cursor: not-allowed;
    color: var(--neutral-4);
    z-index: 0;
    margin: 10px 0;
  }
}

.icon {
  display: inline-block;
  width: 2rem;
  margin: 10px 0 -6px;

  svg {
    width: 22px;
  }
}

.suffix-icon-padding-1 {
  background: none;
  cursor: pointer;
}

.suffix-icon-padding-2 {
  padding-right: calc(4rem + 4px);
}

.iconDateInput {
  display: flex;
  align-items: center;
  width: 100%;

  &:focus {
    box-shadow: none;
  }
}

.prefix {
  position: absolute;
  bottom: 10px;
  left: 10px;
  width: 20px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  margin-right: 10px;
}

.suffix {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-radius: 5px;
  bottom: 10px;
  top: -10px;
  right: 5px;
  // margin-right: 10px;
  width: 20px;
  background: transparent;
}

.icon-read-only {
  visibility: hidden;
}

.prefix-icon-margin {
  padding-left: calc(2rem + 4px);
  padding-top: 30px;
  background: none;
  transform: translateY(-13px);
  cursor: pointer;
}
