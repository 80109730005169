.input-container {
  border-bottom: 1px solid var(--neutral-3);
  margin-bottom: 1.2rem;
}

.disabled {
  border: none;
}

.valid {
  border-bottom: 1px solid var(--success);
  padding-bottom: 0;
}

.invalid {
  margin-top: 10px;
  border-bottom: 1px solid var(--error);
  padding-bottom: 10px;
}

.error {
  margin-top: 10px;
  color: var(--error);
  font-size: 0.75rem;
  line-height: 1;
}

.selected {
  background: var(--neutral-11);
  border-radius: 30px;
  padding: 10px 10px 10px 15px;
  font-size: 0.9em;
  display: inline-flex;
  align-items: center;
  margin-right: 5px;
  margin-bottom: 0.5em;

  button {
    margin-left: 10px;
    display: flex;
  }
}

.transparent {
  border: 0;
  padding: 10px 0;
}

.label {
  padding: 10px 0;
  display: flex;
}

.list {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(161, 161, 161, 0.25);
  display: none;
  position: relative;
}

.open {
  display: flex;
}

.options {
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  flex-direction: column;
  margin: 0;
  padding: 20px 0;
  position: absolute;
  top: 0;
  z-index: 1;
  background: #fff;
  -webkit-box-shadow: 3px 2px 32px -24px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 3px 2px 32px -24px rgba(0, 0, 0, 0.75);
  box-shadow: 3px 2px 32px -24px rgba(0, 0, 0, 0.75);
  border-radius: 8px;
  -ms-overflow-style: none;
  scrollbar-width: none; 
}

.options::-webkit-scrollbar {
  display: none;
}

.item {
  display: flex;

  &:hover {
    background: var(--neutral-11);
  }
}

.no-results {
  list-style-type: none;
  color: var(--neutral-4);
  padding: 5px 20px;
}

.list-item-button {
  width: 100%;
  text-align: left;
  border-radius: 0;
  padding: 5px 20px;

  &:hover {
    background: var(--neutral-11);
  }
}

.clear-selected-button {
  float: right;
  vertical-align: baseline;
  padding: 10px 0;
  margin: 0 10px 0 0;
}
