@import 'src/styles/mixins/font-builder.scss';

.row {
  display: flex;
  flex-direction: row;
  font-weight: 500;
  border-bottom: 1px solid var(--neutral-grey-5);
  align-content: center;
  align-items: center;
  padding: 1em 0 1.5em 0;
  cursor: pointer;

  & > div {
    cursor: pointer;
  }

  & p {
    @include font-builder(14);
  }

  & span {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;

    & span {
      margin-right: 0.5em;
    }
  }
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: var(--neutral-grey-4);
  border-style: dashed;
  color: var(--neutral-3);
  outline: none;
  transition: border 0.24s ease-in-out;
  margin-top: 25px;
  button {
    margin: 20px;
  }
}

.loader {
  position: relative;
  background-color: inherit;
  max-height: 20px;
  max-width: 20px;

  svg {
    width: 20px;
    path {
      stroke: var(--secondary-1);
      stroke-width: 5px;
    }
  }
}

.fileDetails {
  margin-left: 1em;
}
