.notification {
  display: flex;
  position: relative;
  background: var(--white);
  padding: 10px 0 20px 0;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(96, 96, 96, 0.1);
  margin-bottom: 20px;
  width: 350px;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background: var(--success);
}

.uploadNotificationContainer {
  width: 350px;
}

.uploadNotificationTitleContainer {
  display: flex;
  padding: 5px;
}

.uploadNotificationTitle {
  font-weight: 400;
}

.cancelContainer {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  border-top: 2px solid var(--neutral-5);
  border-bottom: 2px solid var(--neutral-5);

  .startUploading {
    margin: 0;
    font-size: 12px;
    color: var(--neutral-1);
  }

  .cancelButton {
    color: var(--tertiary-3);
    font-size: 12px;
  }
}

.fileDetailsContainer {
  display: flex;
  flex-direction: column;
  background: var(--neutral-6);
  border-bottom: 2px solid var(--neutral-5);
}

.fileDetails {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px 0;

  .fileIcon {
    margin: auto 0;
  }

  .fileName {
    max-width: 210px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
  }

  .fileSize {
    font-size: 14px;
  }
}

.timeStamp {
  margin: 0 0 10px 23px;
}

.icons {
  width: 17px;
  height: 17px;
  margin: auto 10px;
  flex-shrink: 0;
}

.close {
  position: absolute;
  top: 5px;
  right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 30px;
  height: 30px;

  &:hover {
    background: var(--neutral-5);
  }
}
