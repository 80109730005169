$orange: #fda61b;
$salmon: #fe5676;
$blue: #2f80ed;
$pink: #ff5ff9;
$purple: #9b51e0;
$cyan: #56ccf2;
$green: #00c48c;
$red: #fd0e3b;
$black: #000000;

:root {
  --success: #{$green};
  --error: #{$red};
  --warning: #{$orange};
  --neutral-0: #{$black};
  --neutral-1: #1b1b1b;
  --neutral-2: #333;
  --neutral-3: #666;
  --neutral-4: #999;
  --neutral-5: #e0e0e0;
  --neutral-6: #f7f7f7;
  --neutral-7: #fafafa;
  --neutral-8: #e5e5e5;
  --neutral-9: #f0f2f5;
  --neutral-10: #222540;
  --neutral-11: #e4e4e4;
  --neutral-12: #c7c7c7;
  --neutral-13: #8e8d8d;
  --neutral-grey-3: #666;
  --neutral-grey-4: #c7c7cc;
  --neutral-grey-5: #e4e4e4;
  --neutral-grey-8: #f0f2f5;
  --interactive-blue: #126bfb;
  --interactive-blue2: #3c80e9;
  --interactive-blue3: #034fc9;
  --purple-1-base: 271, 64%;
  --purple-1-lightness: 50%;
  --purple-1: hsl(var(--purple-1-base), var(--purple-1-lightness));
  --purple-1-dark: hsl(
    var(--purple-1-base),
    calc(var(--purple-1-lightness) - 5%)
  );
  --purple-1: #{$purple};
  --black: #{$black};
  --white: #fff;
  --green: #{$green};
  --red: #{$red};
  --red2: #e3022c;
  --orange: #{$orange};
  --tertiary-1: #{$orange};
  --tertiary-2: #{$salmon};
  --tertiary-3: #{$blue};
  --tertiary-4: #{$pink};
  --tertiary-5: #{$purple};
  --tertiary-6: #{$cyan};
}
