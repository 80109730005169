.wrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.optionsWrapper {
  display: flex;
  flex-direction: row;
}

.option {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 54px;
  height: 54px;
  border: 3px solid #666;
  cursor: pointer;
  font-size: 14px;
  outline: 0;

  &:first-child {
    margin-right: 14px;
  }

  &:focus {
    box-shadow: 0 0 0 2px var(--interactive-blue);
  }

  &.read-only {
    border-color: var(--neutral-4) !important;
    color: var(--neutral-4) !important;
  }
}

.active {
  background: #00c48c;
  border: 3px solid rgba(0, 196, 140, 0.5);
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  color: #fff;

  &.read-only {
    background: var(--neutral-4) !important;
    border-color: var(--neutral-4) !important;
    color: var(--neutral-5) !important;
  }
}

.blue {
  background: var(--interactive-blue);
  border: 0;
}

.label {
  display: flex;
  margin: 20px 0;
}
