$offset: 0.2em;

.wrapper {
  display: flex;
  align-items: center;
  margin: 1ex 0;
  cursor: pointer;

  & svg {
    margin-top: $offset;
    min-width: 20px;
  }
}

.checkbox-invisible {
  margin: 0;
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  outline: none;
  position: relative;
  z-index: -999;
}

.inline {
  display: inline-flex;
}

.checkbox {
  &:focus {
    border: none;
    outline: none;
  }
}

.label {
  padding-left: 8px;
  padding-top: $offset;
  cursor: pointer;
  width: 100%;
}

.required {
  font-style: italic;
  color: var(--error);
  padding-left: 1ex;
  font-size: 14px;
}

.readOnly {
  cursor: not-allowed;
}
