.groupedContainer {
  display: flex;

  & div {
    margin-bottom: 0;
    margin-top: 0;
    flex-basis: 50%;
  }

  & select {
    height: auto;
  }

  & :first-child {
    padding-right: 0.5em;
  }
}

.label {
  font-size: 1rem;
  margin: 1.75em 0px;
  color: var(--neutral-0);
  font-weight: 400;
}
