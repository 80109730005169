html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body,
div,
form,
fieldset,
legend,
label {
  margin: 0;
  padding: 0;
}

fieldset {
  border: none;
  outline: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

th,
td {
  text-align: left;
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6,
th,
td,
caption {
  font-weight: normal;
}

img {
  border: 0;
}

// Improve legebility of anchor tags
a {
  text-decoration-skip-ink: auto;
}

// Remove all animations and transitions for people who prefer not to see them
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

input[type='number'] {
  -moz-appearance: textfield;

  &:-webkit-inner-spin-button,
  &:-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

input[type='search'] {
  -webkit-appearance: none;
}
