@import 'src/styles/mixins/font-builder.scss';

.select {
  width: 100%;
  height: auto;
  appearance: none;
  border-radius: 5px;
  padding: 0 20px 0 10px;
  border: solid 2px transparent;
  background-repeat: no-repeat;
  background-position: center right;
  background-size: 7px;
  background-origin: content-box;
  min-height: 48px;

  &:focus {
    border-color: var(--interactive-blue);
    outline: none;
  }
}

.wrapper {
  max-width: 470px;
  height: 60px;
  position: relative;
}

.wrapper.has-label {
  height: 80px;
  position: relative;
}

.icon {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 28px;
  margin-right: 14px;
}

.subtext {
  margin-top: 1ex;
  color: var(--error);
  min-height: 20px;
  font-size: 0.75rem;
}

.visualLabel {
  color: var(--neutral-0);
  font-size: 1rem;
  line-height: 2;
}

.full-width {
  width: 100%;
  max-width: none !important;
}

.for-form {
  .select {
    height: 49px;
    padding: 1px 2px;
    background-color: transparent !important;
    border-bottom: 1px solid var(--neutral-3);
    border-radius: 0px;
    @include font-builder(14);
  }
}

.read-only.for-form {
  .select {
    padding: 12px;
    border-bottom: 0px;
    border-radius: 5px;
  }
}

.read-only {
  .select {
    background: var(--neutral-8) !important;
    background-image: none !important;
    opacity: 1;
    color: var(--neutral-4);
    cursor: not-allowed;
  }
}
