.timestamp {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: var(--neutral-3);
  margin-top: 8px;
}

.indicator {
  width: 8px;
  height: 8px;
  margin-right: 8px;
  background: var(--success);
  border-radius: 50%;
}

.warning {
  background: var(--warning);
}
