.select {
  display: flex;
  flex-direction: column;
  max-width: 345px;
  height: 70px;
  margin-bottom: 20px;

  select {
    min-height: 48px;
    background-color: var(--neutral-8);
  }
}
