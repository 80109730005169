.constrain {
  width: 50ch;
}

.centered {
  text-align: center;
}

.bold {
  font-weight: bold !important;
}
