@use 'colours';

#root,
html,
body {
  height: 100%;
  overflow: hidden;
  color: var(--neutral-1);
}

button {
  border: 0;
  padding: 0;
  background: none;
  outline: 0;
  border-radius: 5px;
  cursor: pointer;

  &:focus {
    box-shadow: 0 0 0 2px var(--interactive-blue);
  }
}

input {
  border-radius: 0;

  &::-ms-reveal,
  &::-ms-clear {
    display: none;
  }
}

input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.ql-snow.ql-toolbar {
  display: block;
  background: var(--neutral-7);
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
  border: 1px solid var(--neutral-8) !important;
}

.ql-container {
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  font-family: 'museo-sans' !important;
  border: 1px solid var(--neutral-8) !important;
  border-top: none !important;
  min-height: 400px;
}

.wysiwyg-global {
  line-height: 175%;
}

// Overriding npm global WYSIWYG styles
.ql-editor {
  h1 {
    @extend .wysiwyg-global;
    font-size: 2em !important;
  }

  h2 {
    @extend .wysiwyg-global;
    font-size: 1.7em !important;
  }

  h3 {
    @extend .wysiwyg-global;
    font-size: 1.4em !important;
  }

  h4 {
    @extend .wysiwyg-global;
    font-size: 1.1em !important;
  }

  ul {
    margin: 10px 0 0 0 !important;
  }

  li {
    font-size: 1em;
    line-height: 150%;
  }

  p {
    @extend li;
  }
}

@media print {
  body > div,
  html,
  body {
    overflow: auto !important;
    height: auto;
  }
}
