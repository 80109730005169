.label {
  cursor: pointer;
  display: flex;
  flex-direction: row;
}

.icon {
  margin-right: 1em;
}

.children-container {
  margin-top: 1em;
  display: none;
}

.children-container-open {
  @extend .children-container;

  display: block;
}
