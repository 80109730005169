.wrapper {
  position: fixed;
  top: 16px;
  right: 16px;
  z-index: 99999;
}

.notification {
  display: flex;
  position: relative;
  background: var(--white);
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(96, 96, 96, 0.1);
  margin-bottom: 20px;
  width: 350px;
}

.close {
  position: absolute;
  top: 5px;
  right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 30px;
  height: 30px;

  &:hover {
    background: var(--neutral-5);
  }
}

.message {
  font-size: 1rem;
  margin: 0;
  font-weight: 400;
  line-height: 130%;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background: var(--success);
}

.icons {
  width: 17px;
  height: 17px;
  margin-right: 8px;
  flex-shrink: 0;
}
