@import 'src/styles/mixins/font-builder.scss';
@import '~react-datepicker/src/stylesheets/datepicker.scss';

.react-datepicker {
  border-radius: 1em;
  font-family: inherit;
  padding-right: 0 !important;
}

.react-datepicker__input-container {
  min-width: 100px;
}

.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
  top: 17px;

  &:focus {
    box-shadow: none;
  }
}

.react-datepicker__month-container {
  border-radius: 1em;
  padding-bottom: 0.3em;
  border-bottom: none;
  background-color: #fff;

  .react-datepicker__month {
    margin: 0;
    font-weight: bold;
  }
}

.react-datepicker-wrapper {
  margin-top: 0.5em !important;
  width: 100%;
  display: flex;
  padding-left: 10px;
  padding-right: 0 !important;
}

.DatePickerComponent_suffix__1h3j5 {
  margin-top: 1.3em !important;
}

.react-datepicker__header {
  border-bottom: none;
  background-color: #fff;
  margin-right: 0.5em !important;
  margin-left: 0.5em !important;
}

.react-datepicker__current-month {
  margin-top: 10px !important;
  font-size: inherit;
  padding-right: 0 !important;
}

.react-datepicker__input-container {
  margin: auto 0;

  input {
    padding-right: 0;
    width: 100%;
  }
}

.react-datepicker__day--disabled {
  cursor: default;
  color: #ccc;
}

.react-datepicker__day-names {
  margin-top: 12px !important;
  font-size: 12px;
  transform: translateX(5px);
}

.react-datepicker__day-name,
.react-datepicker__week,
.react-datepicker__day {
  margin-right: 0.5em !important;
  margin-left: 0.5em !important;
  padding: 0 !important;
}

.react-datepicker__day-name {
  color: var(--neutral-3);
}

.react-datepicker__day--selected {
  border-radius: 50%;
  background-color: #0dceb3;
  outline: none;
  color: #fff;
  pointer-events: all;
}

.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range,
.react-datepicker__day--highlighted,
.react-datepicker__day:hover {
  border-radius: 50% !important;
  background-color: #0dceb3 !important;
  outline: none !important;
  color: #fff !important;
  pointer-events: all !important;
}

.react-datepicker__day--keyboard-selected {
  color: black;
  background-color: white;

  &:hover {
    background-color: #f0f0f0;
  }
}

.noneHighlighted {
  pointer-events: none;
  color: $datepicker__muted-color;
  background-color: white;
}

.disabledHighlight {
  background-color: var(--neutral-3) !important;
  pointer-events: none !important;
  color: #fff !important;
}

.react-datepicker__day--outside-month {
  visibility: hidden;
}

.react-datepicker__day:hover {
  outline: none !important;
}
