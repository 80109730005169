@import 'src/styles/mixins/font-builder.scss';

%button-base {
  position: relative;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  color: #fff;
  width: fit-content;
  outline: 0;
  border-radius: 5px;
  font-weight: 700;
  font-style: normal;
  padding: 0 22px;
  border: solid 3px transparent;
  transition: background-color 250ms ease, border-color 250ms ease,
    color 250ms ease;
  margin-bottom: 8px;

  @include font-builder(14, 1);

  @media (hover: hover) {
    &:hover {
      transition: background-color 100ms ease-out, border-color 100ms ease-out,
        color 100ms ease-out;
    }
  }
}

%disabled {
  background: var(--neutral-4);
  cursor: not-allowed;

  &:active {
    border: solid 3px transparent;
    background: var(--neutral-4);
  }

  @media (hover: hover) {
    &:hover {
      border: solid 3px transparent;
      background: var(--neutral-4);
    }
  }
}

.disabled {
  cursor: not-allowed;
}

.hidden {
  display: none;
}

.large {
  @extend %button-base;

  height: 47px;
}

.small {
  @extend %button-base;

  height: 35px;
  padding: 0 8px;
}

.inline-small {
  @extend .small;

  margin-right: 1ex;
}

.full-width {
  @extend %button-base;
  font-size: 18px;
  height: 55px;
  width: 100%;
}

.half-width {
  @extend %button-base;
  width: 45% !important;
  height: 42px;
  padding: 0 8px;
}

.primary {
  background: var(--secondary-1);

  @media (hover: hover) {
    &:hover {
      background: var(--secondary-1-dark);
    }
  }

  &:active {
    background: var(--secondary-2);
    border: solid 3px var(--secondary-3);
  }

  &.disabled {
    @extend %disabled;
  }
}

.secondary {
  background: var(--primary);

  @media (hover: hover) {
    &:hover {
      background: var(--primary-dark);
    }
  }

  @media (hover: hover) {
    &:hover {
      background: var(--primary-dark);
    }
  }

  &:active {
    border: solid 3px var(--secondary-2);
  }

  &.disabled {
    @extend %disabled;
  }
}

.tertiary {
  background: transparent;
  box-shadow: inset 999px 999px 0 rgba(0, 0, 0, 0.3);
  border: solid 2px var(--neutral-7);
  color: var(--neutral-7);

  @media (hover: hover) {
    &:hover {
      box-shadow: inset 999px 999px 0 rgba(0, 0, 0, 0.6),
        0 0 3px rgba(255, 255, 255, 0.3);
    }
  }

  &:active {
    box-shadow: inset 999px 999px 0 rgba(255, 255, 255, 0.3),
      0 0 3px rgba(255, 255, 255, 1);
  }

  &:focus {
    border-color: var(--interactive-blue);
    box-shadow: none;
  }

  &.disabled {
    opacity: 0.4;
    box-shadow: none;
    cursor: not-allowed;
  }
}

.quarternary {
  background: var(--neutral-7);
  color: var(--primary);
  border: none;
  box-shadow: inset 0 0 0 2px var(--primary);

  @media (hover: hover) {
    &:hover {
      background: var(--primary);
      color: var(--neutral-7);
    }
  }

  &:active {
    background: var(--primary);
    color: var(--neutral-7);
    box-shadow: inset 0 0 0 3px var(--secondary-2);
  }

  &.disabled {
    box-shadow: inset 0 0 0 2px var(--neutral-4);
    color: var(--neutral-4);
    cursor: not-allowed;

    @media (hover: hover) {
      &:hover {
        background: var(--neutral-7);
      }
    }
  }
}

.text {
  background: transparent;
  color: var(--secondary-3);
  font-weight: normal;

  @include font-builder(18, 1);

  @media (hover: hover) {
    &:hover {
      color: var(--secondary-1);
    }
  }

  &:active {
    color: var(--secondary-1);
  }

  &:disabled {
    color: var(--neutral-4);
    cursor: not-allowed;

    @media (hover: hover) {
      &:hover {
        background: transparent;
      }
    }
  }
}

.danger {
  background: var(--red);
  &.disabled {
    @extend %disabled;
  }
}

.icon {
  position: absolute;
  left: 6px;
  top: 0;
  display: inline-flex;
  width: 22px;
  height: 100%;
  align-items: center;
}

.icon-no-text {
  display: inline-flex;
  justify-content: center;
  width: 22px;
  height: 100%;
  align-items: center;
}

.icon-padding {
  padding-left: 34px;
}

.icon-only {
  background: none;
  width: 42px;
  padding: 0;
  border-radius: 100%;

  @media (hover: hover) {
    &:hover {
      background: var(--neutral-7);
    }
  }

  &:active {
    background: var(--neutral-5);
  }

  .icon {
    width: 100%;
    left: 0;
  }
}

.shaded {
  border: 1.5px solid var(--neutral-4);
  color: var(--neutral-1);
  background: linear-gradient(0deg, #eee 0%, #fff 100%);

  &:active {
    border-color: var(--secondary-1);
  }

  @media (hover: hover) {
    &:hover {
      background: linear-gradient(0deg, #fff 0%, #fff 100%);
    }
  }

  &.disabled {
    box-shadow: inset 0 0 0 2px var(--neutral-4);
    color: var(--neutral-4);
    cursor: not-allowed;

    @media (hover: hover) {
      &:hover {
        background: var(--neutral-7);
      }
    }
  }
}

.blue {
  background: var(--interactive-blue2);

  &:active {
    border-color: var(--secondary-1-dark);
  }

  @media (hover: hover) {
    &:hover {
      background: hsl(217.1, 96.7%, 40%);
    }
  }

  &:disabled {
    background: var(--neutral-4);
    cursor: not-allowed;
  }
}

.outline-blue {
  border-color: var(--interactive-blue2);
  color: var(--interactive-blue2);
  &:active {
    border-color: var(--interactive-blue3);
    color: var(--interactive-blue3);
  }

  @media (hover: hover) {
    &:hover {
      border-color: var(--interactive-blue3);
      color: var(--interactive-blue3);
    }
  }

  &:disabled {
    background: var(--neutral-4);
    cursor: not-allowed;
  }
}

.outline-gray {
  border-color: var(--neutral-grey-4);
  color: var(--neutral-grey-2);
  border-width: 1px;
  font-weight: 500;

  &:active {
    border-color: var(--neutral-grey-4);
    color: var(--neutral-grey-2);
  }

  @media (hover: hover) {
    &:hover {
      border-color: var(--neutral-grey-4);
      color: var(--neutral-grey-2);
    }
  }

  &:disabled {
    background: var(--neutral-4);
    cursor: not-allowed;
  }
}

.outline-red {
  @extend .outline-blue;

  border-color: var(--red);
  color: var(--red);

  &:hover {
    border-color: var(--red2);
    color: var(--white);
    background: var(--red2);
  }
}

.green {
  background: var(--green);

  &:active {
    border-color: var(--secondary-1-dark);
  }

  @media (hover: hover) {
    &:hover {
      background: hsl(120, 100%, 20%);
    }
  }

  &:disabled {
    background: var(--neutral-4);
    cursor: not-allowed;
  }
}

.archive {
  background: var(--purple-1);
  color: var(--white);

  &:active {
    border-color: var(--purple-1-dark);
  }

  @media (hover: hover) {
    &:hover {
      background: hsl(271, 75.8%, 65.8%);
    }
  }

  &:disabled {
    background: var(--neutral-4);
    cursor: not-allowed;
  }
}

.outline-purple {
  @extend .outline-blue;

  border-color: var(--purple-1);
  color: var(--purple-1);
  &:hover {
    border-color: var(--purple-1-dark);
    color: var(--white);
    background: var(--purple-1-dark);
  }
}

.margin {
  margin-right: 2%;
}

.dropdown {
  padding-right: 35px;
  .chevron {
    position: absolute;
    right: 0;
    top: 50%;
    padding-right: 5px;
    transform: translate(0, -50%);
    width: 30px;

    path {
      stroke-width: 3;
    }
  }
}

.dropdownMenu {
  @include font-builder(14, 1);

  position: absolute;
  top: 100%;
  left: -5px;
  z-index: 1000;
  float: left;
  margin-top: 10px;
  color: #000;
  text-align: right;
  list-style: none;
  background-color: var(--neutral-9);
  background-clip: padding-box;
  border: 1px solid var(--neutral-12);
  border-radius: 0.25rem;
  line-height: 30px;
  display: none;
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden;

  .item {
    display: block;
    text-align: left;
    width: calc(100% + 1px);
    border-radius: 0;
    margin: 0;
    padding: 0 15px;
    margin-left: -1px;

    &:first-child {
      margin-top: 10px;
    }

    &:last-child {
      margin-bottom: 10px;
    }

    &:hover,
    &:focus {
      color: #fff;
      text-decoration: none;
      background-color: var(--interactive-blue);
    }
  }
}

.show {
  display: block;
}
