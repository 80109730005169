@import 'src/styles/mixins/font-builder.scss';
@import 'src/styles/mixins/modular-scale.scss';

.input {
  caret-color: var(--neutral-0);
  color: var(--neutral-0);
  width: 100%;
  height: 48px;
  border: none;
  background: none;
  box-shadow: 0 1px 0 var(--neutral-5), 0 0 0 50px #fff inset;
  transition: box-shadow 200ms ease-out;
  -webkit-appearance: none;

  @include font-builder(14);

  &:focus {
    border: 0;
    outline: 0;
    box-shadow: 0 2px 0 var(--neutral-2);
    transition: box-shadow 150ms ease;
  }

  &[readonly] {
    background: var(--neutral-8);
    border-radius: 5px;
    box-shadow: none;
    padding: 12px;
    cursor: not-allowed;
    color: var(--neutral-4);
  }
}

.validated {
  box-shadow: 0 2px 0 var(--success), 0 0 0 50px white inset;

  &:focus {
    box-shadow: 0 1px 0 var(--success);
    transition: box-shadow 150ms ease;
  }
}

.invalidated {
  box-shadow: 0 2px 0 var(--error), 0 0 0 50px white inset;

  &:focus {
    box-shadow: 0 1px 0 var(--error);
    transition: box-shadow 150ms ease;
  }
}

.prefix-icon-padding {
  padding-left: calc(2rem + 12px) !important;
}

.suffix-icon-padding-1 {
  padding-right: calc(2rem + 4px);
}

.suffix-icon-padding-2 {
  padding-right: calc(4rem + 4px);
}

input::placeholder {
  color: var(--neutral-3);
}

.label {
  color: var(--neutral-0);

  @include font-builder(16, 2);
}

.default {
  width: 345px;
  display: flex;
  flex-direction: column;
  margin: 0 0 modular-scale(1, 'rem');
}

.hidden {
  margin: 0;
  display: none;
}

.full-width {
  width: 100%;
}

.subtext {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 1ex;
  color: var(--neutral-3);
  min-height: 20px;

  @include font-builder(12, 1);
}

.hidden .subtext {
  display: initial;
  flex-direction: auto;
  justify-content: normal;
  margin: 0 0 2ex;
  min-height: auto;
  @include font-builder(12, 1);
}

.message {
  padding-bottom: 0.5ex;
}

.input-wrapper {
  position: relative;
}

.error {
  color: var(--error);
}

.icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
}

.button {
  @extend .icon;

  border-radius: 100%;
}

.prefix {
  position: absolute;
  display: flex;
  left: 0;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  bottom: 0;
  top: 0;
}

.suffix {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  bottom: 0;
  top: 0;
  right: 5px;
}

.read-only {
  .suffix {
    right: 12px;
  }
}

.search {
  .input {
    box-shadow: none;
    background: var(--neutral-8);
    border-radius: 5px;
    height: 60px;
    width: 100%;
    border: solid 2px transparent;
    appearance: none;
    padding: 1em;

    &:focus {
      border: solid 2px var(--interactive-blue);
      box-shadow: none;
    }
  }

  .input-wrapper {
    width: 100%;
  }

  .prefix-icon-padding {
    padding-left: calc(3rem + 4px);
  }

  .suffix-icon-padding-1 {
    padding-right: calc(3rem + 4px);
  }

  .suffix {
    right: 10px;
  }

  .prefix {
    left: 10px;
  }
}

.search.for-form {
  .input {
    height: 49px;
  }
}

.search {
  .textarea {
    height: auto;
    min-height: 130px;
    line-height: 1.5em;
    resize: none;
    padding-right: 36px;
  }
}

.comment {
  .input {
    box-shadow: none;
    border-radius: 50px;
    height: 40px;
    width: 100%;
    border: solid 1px var(--neutral-5);
    appearance: none;
    padding: 1em;

    &:focus {
      border: solid 1px var(--neutral-5);
      box-shadow: none;
    }
  }

  .input-wrapper {
    width: 100%;
  }

  .prefix-icon-padding {
    padding-left: calc(3rem + 4px);
  }

  .suffix-icon-padding-1 {
    padding-right: calc(3rem + 4px);
  }

  .suffix {
    right: 10px;
  }

  .prefix {
    left: 10px;
  }
}

.form {
  textarea.input {
    padding: 1em;
  }

  .input {
    box-shadow: none;
    background: var(--neutral-8);
    border-radius: 5px;
    padding: 0 1em;
    &:focus {
      border: solid 2px var(--interactive-blue);
      box-shadow: none;
    }
  }
  .input-wrapper {
    width: 100%;
  }
}

.suffixCount {
  position: relative;
}

.suffixCount {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-size: 12px;
  text-align: right;
  color: var(--neutral-4);

  &.textArea {
    top: calc(-100% + 24px);
  }
}

.inputWithMax {
  padding-right: 10px;
}
